import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightToBracket,
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";

import { useIsMobile } from "../../../hooks";
import { AppDispatch } from "../../../store";
import { VerraLogo, HamburgerMenuIcon, routes } from "../../../assets";
import { ConnectOneRoute } from "../../../components";
import {
  getAgencies,
  getAgencyServices,
  getAgencyStatuses,
  getAgencyFeeTypes,
  getFuelTypes,
  getServices,
  getClassTypes,
  getGeofences,
  getVehicleStatuses,
  getLocationStatuses,
  getLocationHierarchy,
  getServiceStatuses,
} from "../../../redux";
import { resetIdleTimeout } from "../../../utils";

import "./NavBar.scss";

export const ConnectOneNavBar = ({
  user,
  signOut,
  minimizedNav,
  setMinimizedNav,
}: {
  user: any;
  signOut: any;
  minimizedNav: boolean;
  setMinimizedNav: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [activeRoute, setActiveRoute] = useState(window.location.pathname);
  const [openedRoute, setOpenedRoute] = useState(window.location.pathname);
  const [showNav, setShowNav] = useState(!isMobile);

  const isAdmin = user.attributes["custom:userType"] === "admin";
  const truncatedLength = 12;

  useEffect(() => {
    if (user) {
      // Listen for events that indicate user activity
      window.addEventListener("mousemove", resetIdleTimeout);
      window.addEventListener("keydown", resetIdleTimeout);
      window.addEventListener("touchstart", resetIdleTimeout);
    }
  }, [user]);

  useEffect(() => {
    getAppData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowNav(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    setActiveRoute(window.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const handleNavigate = (path: string) => {
    if (isMobile) setShowNav(false);
    if (minimizedNav) {
      setOpenedRoute(path);
      setMinimizedNav(false);
      return;
    }

    navigate(path);
  };

  const handleSignOut = () => {
    handleNavigate("/");
    signOut();
  };

  const getAppData = () => {
    dispatch(getGeofences());
    dispatch(getAgencies());
    dispatch(getAgencyServices());
    dispatch(getAgencyStatuses());
    dispatch(getAgencyFeeTypes());
    dispatch(getFuelTypes());
    dispatch(getServices());
    dispatch(getServiceStatuses());
    dispatch(getClassTypes());
    dispatch(getVehicleStatuses());
    dispatch(getLocationStatuses());
    dispatch(getLocationHierarchy());
  };

  return (
    <>
      {isMobile && (
        <div className="hamburger-menu">
          <span
            data-testid="hamburger-menu"
            className="hamburger-icon"
            onClick={() => setShowNav(!showNav)}
          >
            <HamburgerMenuIcon />
          </span>
          <span className="title">
            <VerraLogo />
            <h1>ConnectOne</h1>
          </span>
        </div>
      )}

      <div>
        {showNav && (
          <div
            className={`${isMobile ? "mobile-navbar-container" : null} ${
              minimizedNav ? "minimized-navbar-container" : null
            } navbar-container`}
            data-testid="navbar"
          >
            <div className="logo-container">
              {!isMobile && (
                <div onClick={() => handleNavigate("/")}>
                  <span className="title">
                    <VerraLogo />
                    <h1>
                      Connect
                      <br />
                      One
                    </h1>
                  </span>
                </div>
              )}

              {isMobile && (
                <div className="hamburger-menu">
                  <span
                    data-testid="x-container"
                    className="x-container"
                    onClick={() => setShowNav(!showNav)}
                  >
                    <span aria-hidden="true">&times;</span>
                  </span>
                  <span className="title">
                    <VerraLogo />
                    <h1>
                      Connect <br />
                      One
                    </h1>
                  </span>
                </div>
              )}

              <div className="minimize-nav-container">
                <span
                  className="minimize-nav-toggle"
                  onClick={() => setMinimizedNav(!minimizedNav)}
                >
                  <FontAwesomeIcon
                    icon={minimizedNav ? faChevronRight : faChevronLeft}
                  />
                </span>
              </div>
            </div>

            <div className="nav-links" data-testid="visible-links">
              {routes.map((route) => (
                <ConnectOneRoute
                  onClick={handleNavigate}
                  route={route}
                  openedRoute={openedRoute}
                  activeRoute={activeRoute}
                  key={route.name}
                  isAdmin={isAdmin}
                  minimizedNav={minimizedNav}
                />
              ))}
            </div>

            <div className="user-container">
              <div className="sign-out-container">
                {/* eslint-disable */}
                <a href="javascript:;" onClick={handleSignOut}>
                  <FontAwesomeIcon
                    icon={faArrowRightToBracket}
                    className="sign-out-icon"
                  />
                  Sign Out
                </a>
              </div>
              <div className="user-info-container" />
              <span className="user-circle">
                {user.username &&
                  Array.from(user.username)[0].toString().toLocaleUpperCase()}
              </span>
              <div className="user-info">
                {user.username.length > truncatedLength
                  ? user.username.slice(0, truncatedLength) + "..."
                  : user.username.charAt(0).toUpperCase() +
                    user.username.slice(1)}{" "}
                <small className="fw-light text-capitalize">
                  {user.attributes["custom:userType"]}
                </small>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
