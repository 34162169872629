import { usStates, agencyFeeTypes } from "../constants";

export const agencyEditState = {
  name: {
    label: "Agency Name",
    value: "",
    name: "name",
  },
  country: {
    label: "Country",
    value: "",
    name: "country",
  },
  state: {
    label: "State",
    value: "",
    name: "state",
    type: "dropdown",
    options: usStates,
  },
  timeZone: {
    label: "Time Zone",
    value: "",
    name: "timeZone",
  },
  currency: {
    label: "Currency",
    value: "",
    name: "currency",
  },
  chargeType: {
    label: "Charge Type",
    value: "",
    name: "chargeType",
  },
  chargeHov: {
    label: "Max HOV",
    value: "",
    name: "chargeHov",
    type: "number",
    max: "10",
  },
  services: {
    label: "Services",
    name: "services",
    type: "checkboxes-string",
    value: [] as string[],
    options: [] as any,
  },
  status: {
    label: "Status",
    name: "status",
    type: "dropdown",
    value: [] as string[],
    options: [] as any,
  },
  fee1: {
    label: "Fee 1",
    value: "",
    name: "fee1",
    type: "number"
  },
  fee2: {
    label: "Fee 2",
    value: "",
    name: "fee2",
    type: "number"
  },
  feeType1: {
    label: "Fee Type 1",
    value: "",
    name: "feeType1",
    type: "dropdown",
    options: agencyFeeTypes,
  },
  feeType2: {
    label: "Fee Type 2",
    value: "",
    name: "feeType2",
    type: "dropdown",
    options: agencyFeeTypes,
  },
  _id: {},
};

export const agencyFilterState = {
  name: {
    label: "Agency Name",
    value: "",
    name: "name",
  },
  status: {
    label: "Status",
    value: "",
    name: "status",
    type: "dropdown",
    options: [] as any,
  },
  services: {
    label: "Services",
    value: "",
    name: "services",
    type: "dropdown",
    options: [] as any,
  },
  state: {
    label: "State",
    value: "",
    name: "state",
    type: "dropdown",
    options: usStates,
  },
  feeType1: {
    label: "Fee 1",
    value: "",
    name: "fee1",
    type: "dropdown",
    options: [] as any,
  },
  feeType2: {
    label: "Fee 2",
    value: "",
    name: "fee2",
    type: "dropdown",
    options: [] as any,
  },
};
