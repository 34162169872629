/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../store";
import { getGeofences } from "../../../redux";
import { useHistoricalData } from "../../../hooks";
import {
  historicalDataHeaders,
  usStates,
  agencyFeeTypes,
} from "../../../assets";
import {
  ConnectOneOverlay,
  ConnectOneInput,
  ConnectOneDropdown,
  ConnectOneButton,
  ConnectOneCheckboxForm,
  ConnectOneTable,
} from "../../../components";

import "./Agencies.scss";

export const AgenciesEditForm = ({
  data,
  onClose,
  handleChange,
  handleCheckbox,
  handleSubmit,
  isLoading = false,
  isCreating = false,
}: {
  data: any;
  onClose: any;
  handleChange: any;
  handleCheckbox: any;
  handleSubmit: any;
  isLoading?: boolean;
  isCreating?: boolean;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const [originalStatus] = useState(data.status.value); // original status
  const [newStatus, setNewStatus] = useState(null); // new status
  const [isStatusChanged, setIsStatusChanged] = useState(false); // track if status has changed
  const [, setIsFeeType1Changed] = useState(false); // track if feeType1 has changed
  const [, setIsFeeType2Changed] = useState(false); // track if feeType2 has changed

  const { historicalData, fetchHistoricalData } = useHistoricalData({
    collectionName: "Agencies",
  });

  useEffect(() => {
    if (!isCreating) {
      fetchHistoricalData({ parentObjectId: data._id.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = (e: any) => {
    const { value } = e.target;
    setNewStatus(value);
    if (value !== originalStatus) {
      setIsStatusChanged(true);
    } else {
      setIsStatusChanged(false);
    }
  };

  const handleFeeTypeChange = (e: any) => {
    const { name, value } = e.target;
    handleChange(e);

    if (name === "feeType1") {
      setIsFeeType1Changed(true);
      if (value === "None") {
        handleChange({
          target: { name: "fee1", value: "0.00" },
        });
        handleChange({
          target: { name: "fee2", value: "0.00" },
        });
        handleChange({
          target: { name: "feeType2", value: "" },
        });
        setIsFeeType2Changed(false);
      }
    } else if (name === "feeType2") {
      setIsFeeType2Changed(true);
      if (value === "None") {
        handleChange({
          target: { name: "fee2", value: "0.00" },
        });

        setIsFeeType1Changed(false);
      }
    }
  };

  const saveDisabled = Object.entries(data).some(
    ([key, value]: [string, any]) => {
      if (key === "servicesString") return false;
      return value.required && value.value === "";
    }
  );

  const confirmSubmit = () => {
    if (isStatusChanged) {
      const actionNames = {
        "6610071c88e2f70ebe9c0b96": "activate",
        "6610075588e2f70ebe9c0b97": "inactivate",
        "6610076788e2f70ebe9c0b98": "inactivate",
      };

      // @ts-ignore
      const actionName = actionNames[newStatus] || "change";

      if (
        window.confirm(
          `This will ${actionName} all related geofences.\nDo you want to continue?`
        )
      ) {
        handleSubmit();
        // Need to update geofences redux state if the status changed
        dispatch(getGeofences());
      } else {
        setNewStatus(null);
        setIsStatusChanged(false);
        handleChange({
          target: { name: "status", value: originalStatus },
        });
      }
    } else {
      handleSubmit();
    }
  };

  return (
    <>
      <ConnectOneOverlay />
      <div
        className="agency-edit-form-container"
        data-testid="agency-edit-form">
        <div className="agency-edit-headers">
          {/* Checking flag to display right title */}
          {!isCreating ? (
            <h1>{`Edit ${data?.prevName?.value}`}</h1>
          ) : (
            <h1>Create New Agency</h1>
          )}
          <span className="close" aria-hidden="true" onClick={onClose}>
            &times;
          </span>
        </div>
        <div className="agency-edit-body">
          <div className="agency-edit-form">
            {data && (
              <>
                <div className="agency-inputs">
                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Name"
                    type="text"
                    name="name"
                    placeholder={""}
                    value={data?.name?.value || ""}
                    required={true}
                  />
                  <ConnectOneDropdown
                    handleChange={(e: any) => {
                      handleChange(e);
                      handleStatusChange(e);
                    }}
                    options={data?.status?.options}
                    label="Status"
                    name="status"
                    required={true}
                    value={newStatus || originalStatus || ""}
                  />

                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Country"
                    type="text"
                    name="country"
                    placeholder={""}
                    value={data?.country?.value || ""}
                    required={true}
                  />
                  <ConnectOneDropdown
                    handleChange={handleChange}
                    options={[
                      { value: "", label: "Select a State", disabled: true },
                      ...usStates,
                    ]}
                    label="State"
                    name="state"
                    required={true}
                    value={data?.state?.value || ""}
                  />
                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Time Zone"
                    type="text"
                    name="timeZone"
                    placeholder={""}
                    value={data?.timeZone?.value || ""}
                    required={true}
                  />
                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Currency"
                    type="text"
                    name="currency"
                    placeholder={""}
                    value={data?.currency?.value || ""}
                    required={true}
                  />
                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Charge Type"
                    type="text"
                    name="chargeType"
                    placeholder={""}
                    value={data?.chargeType?.value || ""}
                    required={true}
                  />

                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Max HOV"
                    type="number"
                    name="chargeHov"
                    value={data?.chargeHov?.value || ""}
                    placeholder="None"
                    min="0"
                    max="10"
                    required={true}
                  />
                </div>

                <div>
                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Fee 1"
                    type="number"
                    name="fee1"
                    value={
                      data?.feeType1?.value === "None"
                        ? "0.00"
                        : data?.fee1?.value || "0.00"
                    }
                    placeholder={""}
                    min="0.0"
                    step={0.01}
                    precision={2}
                    required={false}
                  />
                  <ConnectOneInput
                    handleChange={handleChange}
                    label="Fee 2"
                    type="number"
                    name="fee2"
                    value={
                      data?.feeType1?.value === "None" ||
                      data?.feeType2?.value === "None"
                        ? "0.00"
                        : data?.fee2?.value || "0.00"
                    }
                    placeholder={""}
                    min="0.0"
                    step={0.01}
                    precision={2}
                    required={false}
                    readOnly={
                      data?.feeType1?.value !== "Percent" &&
                      data?.feeType1?.value !== "Flat Fee"
                    }
                  />
                </div>
                <div>
                  <ConnectOneDropdown
                    handleChange={handleFeeTypeChange}
                    options={[
                      {
                        value: "",
                        label: "Select fee type to calculate fee 1",
                        disabled: true,
                      },
                      ...agencyFeeTypes.filter(
                        (type) =>
                          type.value !== data?.feeType2?.value ||
                          type.value === "None"
                      ),
                    ]}
                    label="Fee Type 1"
                    name="feeType1"
                    required={false}
                    value={data?.feeType1?.value}
                  />
                  <ConnectOneDropdown
                    handleChange={handleFeeTypeChange}
                    options={[
                      {
                        value: "",
                        label: "Select fee type to calculate fee 2",
                        disabled: true,
                      },

                      ...agencyFeeTypes.filter(
                        (type) =>
                          type.value !== data?.feeType1?.value ||
                          type.value === "None"
                      ),
                    ]}
                    label="Fee Type 2"
                    name="feeType2"
                    required={false}
                    value={data?.feeType2?.value}
                    placeholder="Enter fee type2"
                    disabled={
                      data?.feeType1?.value !== "Percent" &&
                      data?.feeType1?.value !== "Flat Fee"
                    }
                  />
                </div>

                <div
                  className={`${"mobile-input"} checkbox-input input`}
                  key={`agency-checkbox-input`}>
                  <div>
                    <p className="amplify-text extra-padding">Services:</p>
                  </div>
                  <span key={"agency-checkbox-form"}>
                    <ConnectOneCheckboxForm
                      type={"checkboxes-string"}
                      checkboxData={data?.services}
                      handleCheckbox={handleCheckbox}
                    />
                  </span>
                </div>
              </>
            )}
            {!isCreating && !!historicalData?.length && (
              <div className="historical-data-container">
                <h2>Change Log:</h2>
                <div className="historical-data-button">
                  <ConnectOneTable
                    data={historicalData}
                    headers={historicalDataHeaders}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="agency-edit-footers">
          <ConnectOneButton
            label={data.name.value ? "Save Changes" : "Add Agency"}
            handleClick={confirmSubmit}
            disabled={saveDisabled}
            isLoading={isLoading}
          />
        </div>
      </div>
    </>
  );
};
