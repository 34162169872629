import { useState } from "react";
import { TextField, Text } from "@aws-amplify/ui-react";

import { useIsMobile } from "../../../hooks";
import { ConnectOneButton } from "../../../components";

import "./TextInput.scss";

export const ConnectOneInput = ({
  handleChange,
  type,
  name,
  placeholder = "",
  label = undefined,
  value = "",
  required = false,
  readOnly = false,
  min = undefined,
  max = undefined,
  maxLength = 255,
  exclusiveDate = false,
  step= .01,
  precision,
}: {
  handleChange: any;
  label?: string;
  type: string;
  name: string;
  placeholder?: string;
  value?: string;
  required?: boolean;
  readOnly?: boolean;
  min?: string;
  max?: string;
  step?: number;
  precision?: number;
  maxLength?: number;
  exclusiveDate?: boolean;
}) => {
  const isMobile = useIsMobile();
  const [tempValue, setTempValue] = useState(value);

  const requiredStatus = required && !value;

  const defaultMaxDate: { [key: string]: string } = {
    date: "9999-12-31",
    "datetime-local": "9999-12-31T23:59",
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTempValue(event.target.value);
  };

  const handleSaveDate = () => {
    handleChange({
      target: {
        name,
        value: tempValue,
      },
    });
  };
  return (
    <div
      className={`${isMobile ? "mobile-input" : ""} ${type}-input input ${
        !label ? "input-with-no-label" : ""
      }`}
      key={`${name}-${type}-input`}>
      <TextField
        label={
          <Text>
            {label}
            {requiredStatus && (
              <Text as="span" color="red">
                {" "}
                *
              </Text>
            )}
          </Text>
        }
        type={type}
        name={name}
        onChange={type === "date" ? handleDateChange : handleChange}
        data-testid={`${label}-input`}
        placeholder={placeholder}
        value={type === "date" ? tempValue : value}
        isRequired={required}
        disabled={readOnly}
        min={min ? min : null}
        max={type?.includes("date") ? max || defaultMaxDate[type] : null}
        maxLength={maxLength}
        className="date-input"
        // step={step && step}
        // precision={precision && precision}
      />
      {exclusiveDate && (
        <div
          className="save-date"
          style={!required ? { marginTop: "14px" } : undefined}>
          {type === "date" && (
            <ConnectOneButton
              label="Save Date"
              handleClick={handleSaveDate}
              disabled={!tempValue}
            />
          )}
        </div>
      )}
    </div>
  );
};
