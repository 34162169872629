/* eslint-disable array-callback-return */
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { putVehicle } from "../../../redux";
import { useHistoricalData, useIsMobile } from "../../../hooks";
import { AppDispatch } from "../../../store";
import { historicalDataHeaders, vehicleStatusMap } from "../../../assets";
import { handleTriggerTestMsgEventBus } from "../../../utils";
import {
  ConnectOneCheckboxForm,
  ConnectOneOverlay,
  ConnectOneButton,
  ConnectOneDropdown,
  ConnectOneInput,
  ConnectOneTextBox,
  ConnectOneCheckbox,
  ConnectOneTable,
} from "../../../components";

import "./Vehicles.scss";

export const VehiclesEditForm = ({
  data,
  label = "",
  handleChange,
  handleCheckbox,
  handleGoBack,
  handleSubmit,
  isLoading,
  isCreating = false,
  isReadOnly = false,
}: {
  data: any;
  label: string;
  handleChange: any;
  handleCheckbox: any;
  handleGoBack: any;
  handleSubmit: any;
  isLoading: boolean;
  isCreating?: boolean;
  isReadOnly?: boolean;
}) => {
  const isMobile = useIsMobile();
  const dispatch = useDispatch<AppDispatch>();

  const { historicalData, fetchHistoricalData } = useHistoricalData({
    collectionName: "Vehicles",
  });

  useEffect(() => {
    if (!isCreating) {
      fetchHistoricalData({ parentObjectId: data._id.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleStatusChange = async () => {
    if (
      window.confirm(
        `You are about to ${
          vehicleStatusMap[data.status?.value]?.actionName
        } the services for this vehicle.\nDo you want to continue?`
      )
    ) {
      // Check the activatable status of the current status
      // If activatable, change the status to the pending activation status
      // Otherwise, change the status to the pending deactivation status
      const updatedStatus = vehicleStatusMap[data.status?.value]?.activatable
        ? "65777659cdb69be2659ebfed"
        : "657776d1cdb69be2659ebff5";

      handleChange({
        target: {
          name: "status",
          value: updatedStatus,
        },
      });

      dispatch(
        putVehicle({ editData: { ...data, status: { value: updatedStatus } } })
      );
      handleGoBack("editing");
    }
  };

  const saveDisabled = Object.values(data).some((data: any) => {
    return data.required && data.value === "";
  });

  const handleSendMessages = async () => {
    await handleTriggerTestMsgEventBus({
      data,
      eventBusName: `VehiclePolicyPush-${process.env.REACT_APP_USER_BRANCH}`,
      source: "VerraMobility.ConnectedVehicle.UI",
      detailType: "InVehicleMessageEvent",
      resources: [],
    });

    window.alert(
      "Message sent. The message could take up to 10 seconds to be received."
    );
  };

  return (
    <>
      {!isMobile && <ConnectOneOverlay />}

      <div
        className={`edit-vehicle-container ${
          isMobile ? "mobile-edit-vehicle-container" : null
        }`}
        data-testid={`${label}-edit-form`}>
        <div className="edit-form-headers">
          {!isMobile && (
            <div
              className="x-container"
              onClick={() => handleGoBack("editing")}
              data-testid="search-modal-close-btn">
              <span aria-hidden="true">&times;</span>
            </div>
          )}
          <h1>{isCreating ? `Create New Vehicle` : `Update Vehicle`}</h1>
          <span />
        </div>

        <div className="edit-forms">
          <div className="edit-form-inputs">
            {Object.entries(data).map(
              ([key, value]: [key: string, value: any]) => {
                // If no label then do not render
                // Handle checkboxes in own component
                // Handle status/vehicleEndEffectiveDate in own component
                if (
                  !value.label ||
                  value?.type?.includes("checkbox") ||
                  value.name === "status" ||
                  value.name === "vehicleEndEffectiveDate"
                )
                  return;

                if (value.type === "dropdown") {
                  return (
                    <span key={key}>
                      <ConnectOneDropdown
                        handleChange={handleChange}
                        label={value.label}
                        name={value.name}
                        options={value.options}
                        required={value.required}
                        value={value.value}
                        readOnly={isReadOnly || value.readOnly}
                        alwaysEditable={value.alwaysEditable}
                      />
                    </span>
                  );
                }

                if (value.type === "text-area") {
                  return (
                    <span key={key}>
                      <ConnectOneTextBox
                        handleChange={handleChange}
                        label={value.label}
                        type={value.type}
                        name={value.name}
                        placeholder={value.label}
                        value={value.value}
                        required={value.required}
                        readOnly={isReadOnly || value.readOnly}
                      />
                    </span>
                  );
                }

                return (
                  <span key={key}>
                    <ConnectOneInput
                      handleChange={handleChange}
                      label={value.label}
                      type={value.type}
                      name={value.name}
                      placeholder={value.label}
                      value={value.value}
                      required={value.required}
                      readOnly={isReadOnly || value.readOnly}
                    />
                  </span>
                );
              }
            )}

            {isCreating && (
              <ConnectOneInput
                handleChange={handleChange}
                label="Deactivation Date"
                type="date"
                name="vehicleEndEffectiveDate"
                value={data?.vehicleEndEffectiveDate?.value}
                required={true}
                readOnly={!vehicleStatusMap[data.status?.value].activatable}
              />
            )}
          </div>

          <div className="messages-container">
            <h2>Infotainment Preferences:</h2>
            <span key={"receiveInfotainmentMessages-checkbox"}>
              <ConnectOneCheckbox
                handleChange={handleChange}
                label="Receive Messages"
                name="communicationPreferences.receiveInfotainmentMessages"
                value={
                  data["communicationPreferences"]?.value?.hasOwnProperty(
                    "receiveInfotainmentMessages"
                  )
                    ? data["communicationPreferences"]?.value[
                        "receiveInfotainmentMessages"
                      ]
                    : false
                }
                labelPosition="end"
              />
            </span>
          </div>

          <div className="services-container">
            <h2>Services:</h2>
            {Object.entries(data).map(
              ([key, value]: [key: string, value: any]) => {
                if (value?.type?.includes("checkboxes")) {
                  return (
                    <span key={key}>
                      <ConnectOneCheckboxForm
                        type={value.type}
                        checkboxData={value}
                        handleCheckbox={handleCheckbox}
                        isReadOnly={isReadOnly}
                      />
                    </span>
                  );
                }
              }
            )}
          </div>

          {!isCreating && (
            <div className="status-container">
              <h2>
                Vehicle Status:{" "}
                <span
                  style={{
                    color: vehicleStatusMap[data.status?.value]?.color,
                  }}>
                  {vehicleStatusMap[data.status?.value]?.displayName}
                </span>
                {data.statusNotes?.value.length > 0 &&
                  data.statusNotes?.value !== "Successful" && (
                    <span> - {data.statusNotes.value}</span>
                  )}
              </h2>
              <div className="deactivation-date">
                <p className="deactivation-date-label">Deactivation Date</p>
              </div>
              <div
                className="status-inputs"
                data-testid={"vehicleEndEffectiveDate-input"}>
                <ConnectOneInput
                  handleChange={handleChange}
                  // label="Deactivation Date"s
                  type="date"
                  name="vehicleEndEffectiveDate"
                  value={data?.vehicleEndEffectiveDate?.value}
                  required={true}
                  readOnly={!vehicleStatusMap[data.status?.value].activatable}
                />

                <ConnectOneButton
                  label={vehicleStatusMap[data.status?.value]?.actionButtonText}
                  handleClick={handleStatusChange}
                  color={
                    vehicleStatusMap[data.status?.value]?.actionButtonColor
                  }
                  // Disable the activation button if no deactivation date is set
                  disabled={!data?.vehicleEndEffectiveDate?.value}
                />
              </div>
            </div>
          )}

          {!isCreating && data.provider.value === "Stellantis" && (
            <div className="test-message-container">
              <h2>Vehicle Test Message:</h2>
              <ConnectOneButton
                label="Send Test Message"
                handleClick={() => handleSendMessages()}
                disabled={
                  vehicleStatusMap[data.status?.value]?.displayName !==
                    "Activated" ||
                  data.communicationPreferences.value
                    ?.receiveInfotainmentMessages !== true
                }
              />
            </div>
          )}

          {!isCreating && !!historicalData?.length && (
            <div className="historical-data-container">
              <h2>Change Log:</h2>
              <div className="historical-data-button">
                <ConnectOneTable
                  data={historicalData}
                  headers={historicalDataHeaders}
                />
              </div>
            </div>
          )}
        </div>

        <div className="button-container">
          {isMobile && (
            <div className="close-button">
              <ConnectOneButton
                label="Close"
                handleClick={() =>
                  handleGoBack(`${isCreating ? "results" : "editing"}`)
                }
              />
            </div>
          )}
          <div className="save-button">
            <ConnectOneButton
              label="Save Changes"
              handleClick={handleSubmit}
              isLoading={isLoading}
              disabled={saveDisabled}
            />
          </div>
        </div>
      </div>
    </>
  );
};
